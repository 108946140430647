

































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { plainToClass } from 'class-transformer';
import { inject } from 'inversify-props';
import { DefaultExtensions } from '@/utils/tiptap-extensions/default-extensions';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import EmailComposer from '@/components/email-composer.vue';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ClientModel from '@/models/crm/client.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import EmailModel from '@/models/email.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { ICalendarEvent } from '@/interfaces/crm/calendar-event.interface';
import dayjs from '@/plugins/dayjs';
import ActivityService from '@/services/crm/activity.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';

@Component({
  components: {
    EmailComposer,
  },
})
export default class CrmActivityCalendarEventEmailView extends Vue {
  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  extensions: unknown[] = DefaultExtensions.getExtensionsWithTable();

  formIsValid = true;

  emailModel: EmailModel<IKeyValue<string | number>[]> = plainToClass(EmailModel, {
    cnpj: '',
    from: null,
    to: [],
    cc: [],
    subject: null,
    sendCopy: false,
    message: null,
    metadata: [],
  });

  rules = {
    from: [[ValidationRules.arrayLength, 1], ValidationRules.arrayOfEmails],
    to: [[ValidationRules.minArrayLength, 1], ValidationRules.arrayOfEmails],
    subject: [ValidationRules.required],
    message: [ValidationRules.required],
  };

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true, type: Object })
  event!: ICalendarEvent;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  mounted(): void {
    const currentDate = new Date();

    if (this.userContactInfo.email) {
      this.emailModel.from = this.userContactInfo.email;
    }

    if (this.client.email) {
      this.emailModel.to = [this.client.email];
    }

    this.emailModel.subject = this.getDefaultSubject();
    this.emailModel.message = this.getDefaultMessage();

    this.emailModel.metadata = [
      {
        filename: ActivityService.generateEventAttachmentFilename(this.event, currentDate),
      },
    ];
  }

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.$loading.show({
        container: this.$refs.eventEmailContainer,
        canCancel: false,
      });
      try {
        this.emailModel.cnpj = this.client.cnpjCpf;

        await this.activityService.sendEventByEmail(this.event.id as number, this.emailModel);

        this.$notify.success(this.$t('crm.activityCalendarEventView.successfullySent'));

        this.$emit('complete');
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  get filenames(): string[] {
    return (this.emailModel.metadata || []).map((item) => item.filename as string);
  }

  private getDefaultSubject(): string {
    return `${this.$t('crm.activityCalendarEventView.defaultSubject', {
      company: this.userContactInfo.nomeEmpresa,
      title: this.event.name,
      date: this.eventDate(this.event),
    })}`;
  }

  private getDefaultMessage(): string {
    let message = `${this.$t('crm.activityCalendarEventView.defaultMessage', {
      title: this.event.name,
      date: this.eventDate(this.event),
    })}`;

    if (this.userContactInfo.assinaturaEmail) {
      message += `<br><br>${this.userContactInfo.assinaturaEmail.replaceAll(/(?:\r\n|\r|\n)/g, '<br>')}`;
    }

    return message;
  }

  private eventDate(event: ICalendarEvent): string {
    if (!event) {
      return '';
    }

    let formattedDate = this.$d(event.start, event.timed ? 'dateTime' : 'short');

    if (event.end) {
      const isSameDate = dayjs(event.start).isSame(event.end);
      formattedDate += !isSameDate ? ` - ${this.$d(event.end, event.timed ? 'dateTime' : 'short')}` : '';
    }

    return formattedDate;
  }
}
