var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("prepend"),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('global.emailComposer.from'),"rules":_vm.rules.from,"disable":_vm.disableFrom},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('MultipleEmailField',{attrs:{"rules":rules,"label":label},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('global.emailComposer.to'),"rules":_vm.rules.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('MultipleEmailField',{attrs:{"rules":rules,"label":label},model:{value:(_vm.model.to),callback:function ($$v) {_vm.$set(_vm.model, "to", $$v)},expression:"model.to"}})]}}])})],1),(!_vm.hideCc)?_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('global.emailComposer.cc'),"rules":_vm.rules.cc},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('MultipleEmailField',{attrs:{"rules":rules,"label":label},model:{value:(_vm.model.cc),callback:function ($$v) {_vm.$set(_vm.model, "cc", $$v)},expression:"model.cc"}})]}}],null,false,3947653891)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('global.emailComposer.subject'),"rules":_vm.rules.subject},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto","disabled":_vm.disableSubject},model:{value:(_vm.model.subject),callback:function ($$v) {_vm.$set(_vm.model, "subject", $$v)},expression:"model.subject"}})]}}])})],1),(!_vm.hideSendCopy)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('global.emailComposer.iWouldLikeReceiveCopy'),"hide-details":"auto"},model:{value:(_vm.model.sendCopy),callback:function ($$v) {_vm.$set(_vm.model, "sendCopy", $$v)},expression:"model.sendCopy"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":function($event){_vm.btnAnexo = !_vm.btnAnexo}},model:{value:(_vm.btnAnexo),callback:function ($$v) {_vm.btnAnexo=$$v},expression:"btnAnexo"}},[_vm._v("Adicionar anexos")])],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.btnAnexo),expression:"btnAnexo"}],attrs:{"cols":"12"}},[_c('div',{ref:"dashboardContainer",staticClass:"dashboard-container"},[(_vm.uppyInstance)?_c('dashboard',{attrs:{"uppy":_vm.uppyInstance,"props":_vm.dashboardProps}}):_vm._e()],1)]),_vm._t("editor",function(){return [_c('v-col',{attrs:{"cols":"12"}},[_c('WysiwygEditor',{attrs:{"attachments":_vm.model.attachments,"extensions":_vm.extensions,"min-height":"200","max-height":"300"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)]})],2),(_vm.showAttachments)?_vm._t("attachments",function(){return [_c('v-row',[_c('v-col',[_c('Field',{attrs:{"label":_vm.$t('global.emailComposer.attachments'),"rules":_vm.rules.attachments},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-file-input',{attrs:{"chips":"","multiple":"","counter":"","show-size":"","label":label,"rules":rules,"accept":_vm.accept,"hide-details":"auto"},model:{value:(_vm.model.attachments),callback:function ($$v) {_vm.$set(_vm.model, "attachments", $$v)},expression:"model.attachments"}})]}}],null,false,640075999)})],1)],1)]}):_vm._e(),_vm._t("append")],2)}
var staticRenderFns = []

export { render, staticRenderFns }