import { isArray } from 'lodash';
import { IKeyValue } from '@/interfaces/key-value.interface';

export const toObjectId = (value: IKeyValue | IKeyValue[]): IKeyValue | IKeyValue[] => {
  if (isArray(value)) {
    return value.map((x) => toObjectId(x)) as IKeyValue[];
  }

  return {
    id: value?.id,
  };
};
