var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"calendarEventFormContainer",staticClass:"pa-0 pt-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.title'),"rules":_vm.rules.titulo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.titulo),callback:function ($$v) {_vm.$set(_vm.model, "titulo", $$v)},expression:"model.titulo"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.startAt'),"rules":_vm.rules.dataHoraInicio,"width":115},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('DatePickerField',{attrs:{"rules":rules,"label":label,"hide-details":"auto","disabled":_vm.disableDateFields},on:{"change":_vm.onStartAtChange},model:{value:(_vm.model.dataHoraInicio),callback:function ($$v) {_vm.$set(_vm.model, "dataHoraInicio", $$v)},expression:"model.dataHoraInicio"}})]}}])})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('Field',{attrs:{"width":100}},[_c('TimePickerField',{attrs:{"hide-details":"auto","minute-step":5,"max":_vm.maxTimeStartAt,"disabled":_vm.disableDateFields},on:{"change":_vm.onStartAtTimeChange},model:{value:(_vm.startAtTime),callback:function ($$v) {_vm.startAtTime=$$v},expression:"startAtTime"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.endAt'),"rules":_vm.rules.dataHoraFim,"width":115},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('DatePickerField',{attrs:{"rules":rules,"label":label,"hide-details":"auto","disabled":_vm.disableDateFields},on:{"change":_vm.onEndAtChange},model:{value:(_vm.model.dataHoraFim),callback:function ($$v) {_vm.$set(_vm.model, "dataHoraFim", $$v)},expression:"model.dataHoraFim"}})]}}])})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('Field',{attrs:{"width":100}},[_c('TimePickerField',{attrs:{"hide-details":"auto","minute-step":5,"min":_vm.minTimeEndAt,"disabled":_vm.disableDateFields},on:{"change":_vm.onEndAtTimeChange},model:{value:(_vm.endAtTime),callback:function ($$v) {_vm.endAtTime=$$v},expression:"endAtTime"}})],1)],1)],1),(!_vm.defaultClientType)?_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-radio-group',{attrs:{"row":"","hide-details":"","disabled":_vm.isEditing},on:{"change":_vm.onClientTypeChange},model:{value:(_vm.clientType),callback:function ($$v) {_vm.clientType=$$v},expression:"clientType"}},[_c('v-radio',{attrs:{"color":"secondary","label":_vm.$t('crm.activityCalendarEventForm.client'),"value":"client","disabled":_vm.isEditing}}),_c('v-radio',{attrs:{"color":"secondary","label":_vm.$t('crm.activityCalendarEventForm.prospect'),"value":"prospect","disabled":_vm.isEditing}})],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.isProspectType)?_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.prospect')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
return [_c('v-autocomplete',{attrs:{"items":_vm.clientItems,"loading":_vm.searchingClient,"search-input":_vm.searchClient,"no-filter":"","hide-no-data":"","label":label,"item-text":"nomeFantasia","return-object":"","hide-details":"auto","disabled":_vm.isEditing},on:{"update:searchInput":function($event){_vm.searchClient=$event},"update:search-input":function($event){_vm.searchClient=$event},"change":_vm.onSelectClient},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,false,1100011605)}):_vm._e(),(!_vm.isProspectType)?_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.client')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
return [_c('v-autocomplete',{attrs:{"items":_vm.clientItems,"loading":_vm.searchingClient,"search-input":_vm.searchClient,"no-filter":"","hide-no-data":"","label":label,"item-text":"nomeFantasia","return-object":"","hide-details":"auto","disabled":_vm.isEditing},on:{"update:searchInput":function($event){_vm.searchClient=$event},"update:search-input":function($event){_vm.searchClient=$event},"change":_vm.onSelectClient},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,false,1100011605)}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.representative'),"rules":_vm.rules.representante},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.representativeOptions,"rules":rules,"label":label,"item-text":function (model) { return ((model.codigo) + " - " + (model.nome)); },"return-object":true,"hide-details":"auto"},model:{value:(_vm.model.representante),callback:function ($$v) {_vm.$set(_vm.model, "representante", $$v)},expression:"model.representante"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.origin'),"rules":_vm.rules.origem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.originOptions,"rules":rules,"label":label,"item-text":"descricao","item-value":"id","return-object":true,"hide-details":"auto"},model:{value:(_vm.model.origem),callback:function ($$v) {_vm.$set(_vm.model, "origem", $$v)},expression:"model.origem"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.group'),"rules":_vm.rules.grupo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.groupOptions,"rules":rules,"label":label,"item-text":"descricao","item-value":"id","return-object":true,"hide-details":"auto"},on:{"change":_vm.onGroupChange},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.historyType'),"rules":_vm.rules.tipoHistorico},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.historyTypeOptions,"rules":rules,"label":label,"item-text":"nome","item-value":"id","return-object":true,"hide-details":"auto","loading":_vm.loadingHistoryTypeOptions},model:{value:(_vm.model.tipoHistorico),callback:function ($$v) {_vm.$set(_vm.model, "tipoHistorico", $$v)},expression:"model.tipoHistorico"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showAdditionalFields),expression:"!showAdditionalFields"}],attrs:{"width":"300px"},on:{"click":function($event){_vm.showAdditionalFields = true}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-format-align-left")]),_vm._v(" "+_vm._s(_vm.$t('crm.activityCalendarEventForm.addDescriptionOrAttachment'))+" ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdditionalFields),expression:"showAdditionalFields"}]},[_c('Field',{attrs:{"rules":_vm.rules.descricao},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var rules = ref.rules;
return [_c('WysiwygEditor',{attrs:{"extensions":_vm.extensions,"rules":rules,"min-height":"80","max-height":"150"},model:{value:(_vm.model.descricao),callback:function ($$v) {_vm.$set(_vm.model, "descricao", $$v)},expression:"model.descricao"}})]}}])}),_c('div',{staticClass:"attachments mt-3"},[_vm._l((_vm.model.anexos),function(file){return _c('v-chip',{key:file.id,staticClass:"mr-2 mt-1",attrs:{"close":""},on:{"click":function($event){return _vm.onUploadFile(file)},"click:close":function($event){return _vm.onDeleteAttachment(file)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-paperclip")]),_vm._v(" "+_vm._s(file.filename)+" ")],1)}),_c('v-tooltip',{attrs:{"bottom":"","disabled":!!_vm.client},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline"},on),[_c('v-btn',{staticClass:"mt-1",attrs:{"elevation":"0","small":"","color":"secondary","rounded":"","disabled":!_vm.client,"width":"250px"},on:{"click":_vm.onAddAttachment}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('crm.activityCalendarEventForm.addAttachment'))+" ")],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('crm.activityCalendarEventForm.selectAClientToAttachFiles')))])])],2)],1),_c('Field',{staticClass:"pt-3"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showInternalObservation && !_vm.model.observacao),expression:"!showInternalObservation && !model.observacao"}],attrs:{"rounded":_vm.showAdditionalFields,"small":_vm.showAdditionalFields,"color":_vm.showAdditionalFields ? 'secondary' : '',"width":_vm.showAdditionalFields ? '250px' : '300px'},on:{"click":function($event){_vm.showInternalObservation = !_vm.showInternalObservation}},model:{value:(_vm.showInternalObservation),callback:function ($$v) {_vm.showInternalObservation=$$v},expression:"showInternalObservation"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-outline")]),_vm._v(" "+_vm._s(_vm.$t('crm.activityCalendarEventForm.addObservation'))+" ")],1),_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInternalObservation || _vm.model.observacao),expression:"showInternalObservation || model.observacao"}],attrs:{"label":_vm.$t('crm.activityCalendarEventForm.observation'),"min-height":"50","max-height":"80"},model:{value:(_vm.model.observacao),callback:function ($$v) {_vm.$set(_vm.model, "observacao", $$v)},expression:"model.observacao"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.attendant'),"rules":_vm.rules.atendente},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.attendantOptions,"rules":rules,"label":label,"item-text":"nome","return-object":true,"hide-details":"auto"},model:{value:(_vm.model.atendente),callback:function ($$v) {_vm.$set(_vm.model, "atendente", $$v)},expression:"model.atendente"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('crm.activityCalendarEventForm.closed'),"hide-details":"auto","disabled":_vm.disableEventClosed},model:{value:(_vm.eventClosed),callback:function ($$v) {_vm.eventClosed=$$v},expression:"eventClosed"}})],1)],1),(_vm.showConsolidatedSale)?_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"value":1,"label":_vm.$t('crm.activityCalendarEventForm.consolidatedSale'),"hide-details":"auto"},model:{value:(_vm.model.flagEfetuouVenda),callback:function ($$v) {_vm.$set(_vm.model, "flagEfetuouVenda", $$v)},expression:"model.flagEfetuouVenda"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onSave}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.save')))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.cancel')))])])],1)],1)],1),(_vm.client)?_c('DragDropUpload',{attrs:{"upload-endpoint":_vm.uploadEndpoint,"client-id":_vm.client.codCliente,"place":_vm.uploadPlace,"hash":_vm.uploadHash},on:{"after-close":_vm.onCloseUploader,"delete-files":_vm.onDeleteUploadFiles},model:{value:(_vm.showUploader),callback:function ($$v) {_vm.showUploader=$$v},expression:"showUploader"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }